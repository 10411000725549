import { Tenants } from '@/interfaces/Tenants'
import { colorsDark, colorsLight } from '@/styles/colors'

export const DEFAULT_TENANT: Tenants = {
  name: 'gptmax',
  logo_extended: null,
  cnpj: '',
  id: 0,
  theme: colorsLight,
  theme_dark: colorsDark,
  image_background: '',
  url: '',
  logo: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/logo-gptmax.svg`,
  powered_by: `${process.env.NEXT_PUBLIC_CDN_ONLY_IMAGES}/logo-abstrato.svg`
}
