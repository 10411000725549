import { colorsLight } from '@/styles/colors'
import { AspectRatio, Box, Flex, Text } from '@chakra-ui/react'

interface Props {}

export default function Video({}: Props) {
  return (
    <Flex
      as={'section'}
      flexDirection={'column'}
      align={'center'}
      w={'100%'}
      maxW={1440}
      gap={'32px'}
      px={[0, null, '20px']}
      py={['60px', null, '120px']}
    >
      <Flex
        flexDir={'column'}
        align={'center'}
        color={colorsLight.primary.main}
      >
        <Text
          as={'h1'}
          fontSize={['2xl', null, '3xl']}
          letterSpacing={'-0.9px'}
          textAlign={'center'}
          color={colorsLight.primary.main}
        >
          Saiba tudo sobre o <Text as={'strong'}>GPT Max</Text>
        </Text>
        <Text fontSize={'lg'} as={'h2'} textAlign={'center'}>
          Aperte play e entenda mais sobre a ferramenta
        </Text>
      </Flex>
      <Box
        id="video"
        bgColor={'blackAlpha.200'}
        w={'100%'}
        maxW={1024}
        overflow={'hidden'}
        borderRadius={[0, null, '16px']}
      >
        <AspectRatio ratio={16 / 9}>
          <video
            src={`${process.env.NEXT_PUBLIC_CDN}/videos/video-lp-gpt-max.mp4`}
            controls
            autoPlay={false}
            muted={false}
            loop={false}
            style={{ width: '100%', height: '100%' }}
          >
            Seu navegador não suporta o elemento de vídeo.
          </video>
        </AspectRatio>
      </Box>
    </Flex>
  )
}
