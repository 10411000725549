import { useToast } from '@chakra-ui/react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Socket } from 'socket.io'
import io from 'socket.io-client'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'

interface SocketProviderProps {
  children: React.ReactNode
}

export interface EventProps {
  name: string
  path: string
  id: number | string
  message: any
}

export interface SocketContext {
  socket: Socket | null
  progress: SocketDataProps | null
  eventFinished: EventProps | null
}

export interface SocketDataProps {
  title: string
  message: string
  duration?: number
  uuid?: string
  actions?: any
  progressValue?: number
}

const SocketContext = createContext<SocketContext>({} as SocketContext)

export const SocketProvider = ({ children }: SocketProviderProps) => {
  const [socket, setSocket] = useState<Socket | any>(null)
  const [progress, setProgress] = useState<SocketDataProps | null>(null)
  const [eventFinished, setEventFinished] = useState<EventProps | null>(null)
  const toast = useToast()
  const { data: session } = useSession()
  const { pathname } = useRouter()

  useEffect(() => {
    if (!pathname.includes('video-studio')) return

    const socketInitializer = async () => {
      if (session?.user?.id && !socket) {
        await fetch('/api/socket')
        const newSocket = io()

        newSocket.on('connect', () => {
          console.log('connected')
          newSocket.emit('join', `${session?.user?.id}`)
        })

        setSocket(newSocket)

        return () => newSocket.disconnect()
      }
    }
    socketInitializer().then()
  }, [session?.user?.id])

  useEffect(() => {
    if (socket) {
      socket.on('progress', (data: SocketDataProps) => {
        if (data) {
          setProgress(data)
        }

        if (data?.uuid && !toast.isActive(data.uuid)) {
          toast({
            title: data.title,
            description: data.message,
            status: 'info',
            position: 'bottom-right',
            isClosable: true,
            duration: data.duration
          })
        }
      })

      socket.on('event_finished', (data: EventProps) => {
        setEventFinished(data)
      })

      // socket.on('image_created', (data) => {
      //   .....
      // })
      //adicionar mais eventos para o socket ouvir aqui.
    }
  }, [socket])

  return (
    <SocketContext.Provider value={{ socket, progress, eventFinished }}>
      {children}
    </SocketContext.Provider>
  )
}

// Hook personalizado para usar o socket
export const useSocket = (): SocketContext => {
  return useContext(SocketContext)
}
